
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[clientId]/efficient-purchase",
      function () {
        return require("private-next-pages/[clientId]/efficient-purchase.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[clientId]/efficient-purchase"])
      });
    }
  