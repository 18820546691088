// react
import { useEffect } from 'react';
// components
import { Layouts } from 'commons/components';
import { DragAndDropUploaderModule } from 'commons/modules';
import HomeModule from '@commons/modules/HomeModule/HomeModule';
// rsuite
import { Grid } from 'rsuite';
// context open cart
import { useCreateOrder, useProgressBarPriceContext, useRecommendedProducts } from 'context';
// context search
import { usePointOfSale } from 'context';
import { useDragAndDrop } from 'context/dragAndDropContext';
// utils
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';

const index = () => {
    // context create order
    const { resetStore } = useCreateOrder();
    const { pointOfSale } = usePointOfSale();

    // zetti
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    // context drag and drop
    const { setVisibleDragDrop, visibleDragDrop } = useDragAndDrop();

    // context recommended products
    const { recommendedProducts } = useRecommendedProducts();

    const { resetStates } = useProgressBarPriceContext();

    // init
    useEffect(() => {
        if (!isZetti && !isGestorERP) {
            setVisibleDragDrop(false);
        }

        if (isZetti || isGestorERP) {
            resetStates();
        }
        resetStore();
    }, []);

    useEffect(() => {
        if (recommendedProducts?.length) {
            localStorage.removeItem('recommended_products_viewed');
            localStorage.removeItem('drugManufacturerStatusList');
            localStorage.removeItem('firstPosLoginFetched');
        }
    }, [recommendedProducts]);

    const validateERP = !isZetti && !isGestorERP;

    return (
        <>
            <Layouts>
                <Grid fluid style={{ paddingRight: 0, paddingLeft: 0 }}>
                    {/*  ref = fix input don't scroll with us  */}
                    {<HomeModule />}
                    {validateERP && visibleDragDrop && <DragAndDropUploaderModule />}
                </Grid>
            </Layouts>
        </>
    );
};

export default index;
